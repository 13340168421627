<template>
    <div class="modal" v-if="modelValue" :class="{ active : modelValue }">
        <div class="modal_container">
            <div class="modal_header border-bottom">
                <h1>Zen Planner Integration</h1>
                <button class="close_btn left_out" @click="closeModal()"><i class="fas fa-long-arrow-alt-right"></i></button>
            </div>
            <div class="modal_body">
                <div class="integration_info">
                    <img src="@/assets/images/zenplanner.jpg" alt="" class="w-100">
                    <p class="mt-2">Log in to your Zen Planner account In the menu toolbar (on the left side), click Setup. Go to the External services section and click on "Web Hooks". <br/> <br/>Go <a href="https://new.onboardme.io/zen/planner">here</a>. Enter the following key in the "Account ID" Field</p>
                </div>
                <div class="setting_wpr">
                    <div class="form_grp">
                        <div class="group_item">
                            <div class="field_wpr">
                                <input type="text" :value="user.access_token" placeholder="API Key">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal_footer">
                <button type="button" class="btn cancel_btn" @click="closeModal()">Cancel</button>
                <button class="btn save_btn" @click="copyCode()">Copy</button>
            </div>
        </div>
    </div>
</template>

<script>
    import { copyText } from 'vue3-clipboard'
    import { mapState } from 'vuex'

    import Toastr from '@/utils/Toastr'

    export default {
        name: 'Zen Planner Integration',

        data () {
            return {
                form: {

                },
            };
        },

        props: {
            modelValue: Boolean,
        },

        emits: ['update:modelValue'],

        computed: mapState({
            user: state => state.authModule.user,
        }),

        watch:{
            modelValue(val){
                if(val){
                    document.body.classList.add('modal-open');
                }else{
                    document.body.classList.remove('modal-open');
                }
            }
        },

        methods: {
            closeModal () {
                const vm = this;

                vm.$emit('update:modelValue', false);
            },

            copyCode () {
                const vm = this;

                copyText(vm.user.access_token, undefined, (error, event) => {
                    if (error) {
                        Toastr.error('Failed to copy code!');
                    } else {
                        Toastr.success('Code has been copied successfully!');
                    }
                })
            },
        },
    }
</script>

<style scoped>
    .integration_info p {
        font-size: 15px;
        line-height: 23px;
        color: #121525;
        font-weight: 400;
    }
    .integration_info p a{
        text-decoration: none;
        color: #2f7eed;
    }
</style>
